import React from 'react';
import PropTypes from 'prop-types';

const CalcHeader = ({ title }) => (
  <div className='calc-header'>
    <span className='calc-header-title'>{title}</span>
  </div>
);

export default CalcHeader;

CalcHeader.propTypes = {
  title: PropTypes.string,
};
