import { useState, useEffect } from 'react';

const useDebounce = (data, time) => {
  const [v, sv] = useState(data);
  const [d, sd] = useState(data);
  useEffect(() => {
    const t = setTimeout(sd, time, v);
    return clearTimeout.bind(null, t);
  }, [v]);
  return [d, (d, i) => i ? sd(d) : sv(d)];
};

export default useDebounce;
