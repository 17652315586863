import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Rehype from 'rehype-react';
import Calculator from '../Calculator';
import layoutContext from '../layoutContext';


const RB = 'input[type=\'radio\']';
const slider = 'slider';

const Slider = () => {
  const { cult } = useContext(layoutContext);

  const {allMarkdownRemark} = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          limit: 2000, sort: {fields: [frontmatter___date], order: DESC},
          filter: {fileAbsolutePath: {regex: "/slider/"}}
        ) { 
          edges { 
              node { 
                htmlAst 
              } 
            } 
          }
      }
  `);

  const { edges } = allMarkdownRemark;
  const { node: { htmlAst } } = edges[cult === 'ru' ? 0 : 1];

  const [slide, setSlide] = useState({0: 0, 1: 0});
  const [delay, setDelay] = useState();

  const renderAst = new Rehype({
    createElement: React.createElement,
    components: {
      'calculator': Calculator
    },
  }).Compiler;

  useEffect(() => {
    const sliderContainer = document.getElementsByClassName(slider);
    const delays = Array.prototype.map.call(sliderContainer, (slider) => {
      const radioButtons = slider.querySelectorAll(RB);
      return Array.prototype.map.call(radioButtons, (btn) => {
        return +btn.getAttribute('data-delay');
      });
    });
    setDelay({...delays});
    setSlide({...delays.map((_, index) => index)});
  }, []);


  useEffect(() => {
    const onClick = (index, i) => {
      setSlide({...slide, [i]: index});
    };
    const sliderContainer = document.getElementsByClassName(slider);
    Array.prototype.forEach.call(sliderContainer, (slider, i) => {
      const radioButtons = slider.querySelectorAll(RB);
      Array.prototype.forEach.call(radioButtons, (btn, index) => {
        btn.addEventListener('click', () => onClick(index, i));
      });
    });
    return () => {
      Array.prototype.forEach.call(sliderContainer, (slider, i) => {
        const radioButtons = slider.querySelectorAll(RB);
        Array.prototype.forEach.call(radioButtons, (btn, index) => {
          btn.removeEventListener('click', () => onClick(index, i));
        });
      });
    };
  }, []);

  useEffect(() => {
    if (slide) {
      const sliderContainer = document.getElementsByClassName(slider);
      Array.prototype.forEach.call(sliderContainer, (slider, i) => {
        const radioButtons = slider.querySelectorAll(RB);
        Array.prototype.forEach.call(radioButtons, (btn, index) => {
          btn.checked = (slide[i] === index);
        });
      });
    }
  }, [slide]);

  useEffect(() => {
    const sliderContainer = document.getElementsByClassName(slider);
    const intervals = Array.prototype.map.call(sliderContainer, (slider, i) => {
      const radioButtons = slider.querySelectorAll(RB);
      if (slide && delay) {
        radioButtons[slide[i]].setAttribute('checked', true);
        return setInterval(() => {
          setSlide(slide => slide[i] === radioButtons.length - 1 ? 
            {...slide, [i]: 0} : 
            {...slide, [i]: slide[i] + 1});
        }, delay[i][slide[i]] * 1000);
      }
      return setInterval(() => {});
    });
  
    return () => {
      intervals.map(interval => clearInterval(interval));
    };
  }, [slide, delay]);

  return (
    <>
      {renderAst(htmlAst)}
    </>
  );
};

export default Slider;
