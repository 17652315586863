import React from 'react';
import PropTypes from 'prop-types';


const CalcUntil = ({ text, isDayRed, redText }) => (
  <p className='calc-until'>
    {'До:'}
    <span className='blue-text'>{text}</span>
    <span className={isDayRed ? 'red-text' : 'blue-text'}>
      {redText}
    </span>
  </p>
);

export default CalcUntil;

CalcUntil.propTypes = {
  text: PropTypes.string,
  isDayRed: PropTypes.bool,
  redText: PropTypes.string,
};
