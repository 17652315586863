import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FLIP_TIMEOUT = 5000;

const CalcCircle = ({ title }) => {
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    const int = setInterval(setFlip, FLIP_TIMEOUT, f => !f);
    return () => clearInterval(int);
  }, []);

  return (
    <div className='first-credit'>
      <div
        onClick={() => setFlip($ => !$)}
        className={`first-credit-inner ${flip ? '' : 'no-'}fliped`}
      >
        <div className='first-credit-front'>
          <span className='first-credit-text'>
            <strong>0,01%</strong>
            {title}
          </span>
        </div>
        <div className='first-credit-back'> </div>
      </div>
    </div>
  );
};


export default CalcCircle;

CalcCircle.propTypes = {
  title: PropTypes.string,
};
