/* eslint-disable no-extra-boolean-cast */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useState, useCallback, useEffect } from 'react';

import CalcHeader from './CalcHeader';
import CalcCounter from './CalcCounter';
import CalcUntil from './CalcUntil';
import CalcDigits from './CalcDigits';
import CalcCircle from './CalcCircle';
import CalcLoader from './CalcLoader';


import { CALC_TOKENS, WEEKDAYS } from '../../constants/Languages';
import layoutContext from '../layoutContext';
import useDebounce from './useDebounce';
import { API_URL } from './api';
import Modal from './Modal';

import './calculator.scss';

const DEFAULT = {
  minSum: 500,
  maxSum: 15000,
  sumStep: 100,
  sum: 2000,

  minDays: 5,
  maxDays: 30,
  daysStep: 1,
  days: 30,

  waitTimeout: 250
};

//const COUNTER = 'counter';
//const GET_PARAMS = 'getParams';

const Calculator = () => {
  const { cult } = useContext(layoutContext);
  const [isModalOpen, openModal] = useState(false);
  const weekdays = WEEKDAYS[cult];
  const tokens = CALC_TOKENS[cult];
  const [apijson, setApijson] = useState(null);
  const [sumInput, setSumInput] =
    useDebounce(DEFAULT.sum, DEFAULT.waitTimeout);
  const [daysInput, setDaysInput] =
    useDebounce(DEFAULT.days, DEFAULT.waitTimeout);
  const [output, setOutput] = useState({
    percent: 0,
    total: 0,
    isDayRed: false,
    until: { text: null, redText: null }
  });

  const fixMenu = useCallback(() => {
    document.getElementsByTagName('body')[0].classList.toggle('fixed');
  }, []);

  useEffect(() => {
    if (!!process.env.CALC_CACHE) {
      const cachedCalc = require('../../../apiex.json');
      setApijson(cachedCalc.map(row => row.split(',').map(n => +n)));
    } else {
      return fetch(API_URL)
        .then(res => res.json())
        .then(result => result.map(row => row.split(',').map(n => +n)))
        .then(setApijson);
    }
  }, []);

  useEffect(() => {
    calculateValues();
  }, [sumInput, daysInput, apijson]);

  useEffect(() => {
    let summRange = null, badge = null;
    function inputListener() {
      if (+summRange.value > 5000) {
        badge.classList.add('shown');
      } else {
        badge.classList.remove('shown');
      }
      const x =
        summRange.offsetLeft - badge.offsetWidth / 2 +
        Math.round(
          summRange.clientWidth * (+summRange.value / +summRange.max)
        );
      badge.style.left = `${x - 5}px`;
    }
    function blurListener() {
      badge.classList.remove('shown');
    }

    if (apijson && typeof document !== undefined) {
      summRange = document.querySelector('.calc-body #sum-range');
      badge = document.querySelector('.calc-body #calc-summ-badge');
      badge.style.top = `${summRange.offsetHeight - 10}px`;
      summRange.addEventListener('input', inputListener);
      summRange.addEventListener('blur', blurListener);
      inputListener();
      return () => {
        summRange.removeEventListener('input', inputListener);
        summRange.removeEventListener('blur', blurListener);
      };
    }
  }, [apijson]);

  /* const gtmPush = useCallback(
    (type) => {
      if (typeof window !== undefined) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'eventCategory': 'form',
          'eventAction': 'sent',
          'eventLabel': type,
          'eventNonInteraction': 'False',
          'event': 'autoEvent'
        });
      }
    },
    [],
  );*/
  /* const getCookie = useCallback(
    (name) => {
      if (typeof document !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        const data = parts.pop().split(';').shift();

        const getParams = data != '' ? JSON.parse(data) : {};

        return Object.keys(getParams).reduce((url, current) => {
          return `${url}&${current}=${getParams[current]}`;
        }, '');
      }
    },
    [],
  );*/

  const {
    minSum,
    maxSum,
    sumStep,
    minDays,
    maxDays,
    daysStep } = DEFAULT;

  const calculateValues = useCallback(() => {
    if (apijson) {
      const price =
        apijson[daysInput][apijson[0].findIndex(v =>
          v === sumInput * 100)
        ];
      const now = new Date();
      now.setDate(now.getDate() + daysInput);
      setOutput({
        total: (price / 100).toFixed(2),
        percent: ((price - sumInput * 100) / 100).toFixed(2),
        isDayRed: [0, 6].includes(now.getDay()),
        until: {
          text: [
            now.getDate().toString().padStart(2, '0'),
            (now.getMonth() + 1).toString().padStart(2, '0'),
            now.getFullYear()
          ].join('.'),
          redText: `(${weekdays[now.getDay()]})`
        }
      });
    }
  }, [sumInput, daysInput, apijson]);


  return (
    <>
      <div className='calc-container'>
        <CalcHeader title={tokens.title} />
        <div className='calc-body'>
          <CalcCounter
            setValue={setSumInput}
            title={tokens.summ}
            value={sumInput}
            min={minSum}
            max={maxSum}
            step={sumStep}
            counterValue='грн'
          />
          <CalcCounter
            setValue={setDaysInput}
            title={tokens.duration}
            value={daysInput}
            min={minDays}
            max={maxDays}
            step={daysStep}
            counterValue='дн'
          />
          <div id='calc-summ-badge'>{tokens.repetitiveCredit}</div>
          <CalcUntil
            text={output.until.text}
            isDayRed={output.isDayRed}
            redText={output.until.redText}
          />
          <CalcDigits
            value={tokens.usePercent}
            digits={output.percent}
            grey
          />
          <CalcDigits
            value={tokens.total}
            digits={output.total}
          />
          <a
            className='btn btn-primary modal-btn' target={'_blank'}
            rel={'noreferrer'}
            href={
              'https://bit.ly/3wSe59r'
            }
          >{tokens.getMoney}</a>
          <CalcCircle title={tokens.firstCredit} />
        </div>
        {!apijson && (
          <CalcLoader />
        )}
      </div>
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          openModal={openModal}
          fixMenu={fixMenu}
          sum={sumInput}
          days={daysInput}
        />
      )}
    </>
  );
};

export default Calculator;
