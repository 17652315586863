import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const [container] = useState(document.createElement('div'));
  useEffect(() => {
    container.setAttribute('class', 'modal-container');
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export default Portal;
