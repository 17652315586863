import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CaclSubstarctButton from './CaclSubstarctButton';

import layoutContext from '../layoutContext';

const CalcCounter = ({
  title,
  setValue,
  value,
  min,
  max,
  step,
  counterValue }) => {

  const { counter, setCounter } = useContext(layoutContext);
  return (
    <div className='calc-row counter'>
      <p className='blue-text counter-label'>{title}:</p>
      <div className='counter-block'>
        <CaclSubstarctButton
          handleClick={() => setValue(inputValue => (inputValue <= min)
            ? inputValue : inputValue - step, true
          )}
          value='-'
        />
        <div className='counter-number'>{value.toLocaleString('ru')}</div>
        <CaclSubstarctButton
          handleClick={() => setValue(inputValue => (inputValue >= max)
            ? inputValue : inputValue + step, true
          )}
          value='+'
        />
        <span className='counter-value'> {counterValue}.</span>
      </div>
      <input
        id='sum-range'
        className='calc-slider'
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onMouseDown={counterValue === 'грн' ?
          () => setCounter({
            summCount: counter.summCount + 1,
            daysCount: counter.daysCount
          }) :
          () => setCounter({
            summCount: counter.summCount,
            daysCount: counter.daysCount + 1
          })}
        onChange={e => setValue(+e.target.value, true)}
      />
    </div>
  );
};
export default CalcCounter;

CalcCounter.propTypes = {
  title: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  counterValue: PropTypes.string
};
