import React from 'react';
import PropTypes from 'prop-types';

const CalcDigits = ({ value, digits, grey }) => (
  <div className={`calc-row ${grey ? 'grey' : 'not-grey'}`}>
    <p className='calc-percent'>{value}:</p>
    <div className='calc-total-digits'>
      {digits}
      <span>грн.</span>
    </div>
  </div>
);

export default CalcDigits;

CalcDigits.propTypes = {
  value: PropTypes.string,
  grey: PropTypes.bool,
  digits: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
