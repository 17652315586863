import React, { useContext } from 'react';
import PT from 'prop-types';
import Portal from './Portal';
import CloseModal from './CloseModal';

import { CALC_TOKENS } from '../../constants/Languages';
import layoutContext from '../layoutContext';

import './Modal.scss';

const Modal = ({ isModalOpen, openModal, fixMenu }) => {

  /*const getCookie = useCallback(
    (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);

      const getParams = JSON.parse(parts.pop().split(';').shift());

      return Object.keys(getParams).reduce((url, current) => {
        return `${url}&${current}=${getParams[current]}`;
      }, '');
    },
    [],
  );*/

  /*const gtmPush = useCallback(
    (type) => {
      if (typeof window !== undefined) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'eventCategory': 'form',
          'eventAction': 'sent',
          'eventLabel': type,
          'eventNonInteraction': 'False',
          'event': 'autoEvent'
        });
      }
    },
    [],
  );*/

  /*const GET_PARAMS = 'getParams';
  const COUNTER = 'counter';*/

  const { cult } = useContext(layoutContext);
  const tokens = CALC_TOKENS[cult];
  return (
    <Portal>
      <div className='modal'>
        <CloseModal
          openModal={openModal}
          isModalOpen={isModalOpen}
          fixMenu={fixMenu}
        />
        <a
          className='btn btn-primary modal-btn' target={'_blank'}
          rel={'noreferrer'}
          href={
            'https://bit.ly/3wSe59r'
          }
        >{tokens.getMoney}</a>
        <a
          className='btn btn-primary modal-btn' target={'_blank'}
          rel={'noreferrer'}
          href={
            'https://bit.ly/3wSe59r'
          }
        >{tokens.getMoney}</a>
      </div>
    </Portal>
  );
};

export default Modal;

Modal.propTypes = {
  isModalOpen: PT.bool,
  openModal: PT.func,
  fixMenu: PT.func,
  sum: PT.number,
  days: PT.number
};
