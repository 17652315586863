import React from 'react';
import PropTypes from 'prop-types';

import './CloseModal.scss';

const CloseModal = ({ isModalOpen, openModal, fixMenu }) => (
  <span
    className='close-modal'
    onClick={() => {
      openModal(!isModalOpen);
      fixMenu();
    }}
  >
    ×
  </span>
);

export default CloseModal;

CloseModal.propTypes = {
  isModalOpen: PropTypes.bool,
  openModal: PropTypes.func,
  fixMenu: PropTypes.func
};
