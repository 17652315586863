import React from 'react';
import PropTypes from 'prop-types';

const CaclSubstarctButton = ({ handleClick, value }) => (
  <button
    className='calc-btn'
    onClick={handleClick}
  >{value}</button>
);

export default CaclSubstarctButton;


CaclSubstarctButton.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.string,
};
